import { useState, useEffect } from "react";
import { fetchCockpitData } from "./utils/cockpit";
import RelatedNetwork from "./components/RelatedNetwork";
import Message from './components/Message';
import Info from './components/Info';

const App = () => {

  const [nodesData, setNodesData] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [info, setInfo] = useState({});
  const [informationIsActive, setInformationIsActive] = useState(false);
  const [selectedNode, setSelectedNode] = useState({});
  const [windowDimensions, setWindowDimensions] = useState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  const [visJsNetwork, setVisJsNetwork] = useState({});

  useEffect(() => {
    const handleGetNodes = (data) => {
      const nodeEntries = [];
      for (let entry of data.entries) {
        const newNode = {
          id: entry._id,
          label: entry.name,
          type: 'primary'
        }
        nodeEntries.push(newNode);
      }
      setNodesData(data.entries);
      setNodes(nodeEntries);
    }
    const handleGetEdges = (data) => {
      const edgeEntries = [];
      for (let entry of data.entries) {
        const newEdge = {
          id: entry._id,
          label: entry.label,
          from: entry.from._id,
          to: entry.to._id
        }
        edgeEntries.push(newEdge);
      }
      setEdges(edgeEntries);
    }
    fetchCockpitData('nodes', handleGetNodes, true);
    fetchCockpitData('edges', handleGetEdges, true);
    fetchCockpitData('info', setInfo, false);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth
      });
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div
      className="main"
      style={{
        height: windowDimensions.windowHeight + 'px'
      }}
    >
      <h1 className="title">
        {
          info.information && !selectedNode.name && informationIsActive === false ?
            <button onClick={() => { setInformationIsActive(true); }} className="title__button">AI Justice Matrix</button>
          :
            `AI Justice Matrix`
        }
      </h1>
      <div
        className="graph"
        style={{
          height: `100%`,
          width: `100%`
        }}
      >
        {
          nodes[1] && edges[1] &&
          <RelatedNetwork
            nodes={nodes}
            edges={edges}
            setVisJsNetwork={setVisJsNetwork}
            setSelectedNode={setSelectedNode}
            selectedNode={selectedNode}
          />
        }
      </div>
      {
        nodesData.map(
          (node, i) => (
            selectedNode &&
            node._id === selectedNode &&
            <Message
              visJsNetwork={visJsNetwork}
              node={node}
              key={i}
              windowDimensions={windowDimensions}
              setSelectedNode={setSelectedNode}
            />
          )
        )
      }
      {
        informationIsActive === true &&
        <Info info={info} setInformationIsActive={setInformationIsActive} />
      }
    </div>
  );
}

export default App;
