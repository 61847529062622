import ReactMarkdown from 'react-markdown';

const Info = (props) => {

  const { info, setInformationIsActive } = props;

  return (
    <div className="message">
      <div className="message__inner">
        <header className="message__header">
          <h2 className="message__title">Information</h2>
          <button className="message__close"
            onClick={(e) => {
              e.preventDefault();
              setInformationIsActive(false);
            }}
          >Close</button>
        </header>
        <div className="message__content">
          <ReactMarkdown>{info.information}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}

export default Info;