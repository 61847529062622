import { useRef, useEffect } from "react";
import { Network } from "vis-network";

const RelatedNetwork = (props) => {
	const { nodes, edges, selectedNode, setSelectedNode, setVisJsNetwork } = props;
	const visJsRef = useRef();

	useEffect(() => {

	}, [selectedNode]);

	useEffect(() => {
		const network = visJsRef.current &&
			new Network(
				visJsRef.current,
				{
					nodes: nodes,
					edges: edges
				},
				{
					autoResize: true,
					edges: {
						color: "#000"
					},
					nodes: {
						color: {
							background: "#0f0",
							border: "#000",
							highlight: {
								background: "#f0f",
								border: "#000"
							}
						}
					}
				}
			);
			if (visJsRef.current) {
				setVisJsNetwork(network);
			}
			network?.on("selectNode", (event) => {
				if (event.nodes?.length === 1) {
					setSelectedNode(event.nodes[0]);
				};
			});
			network?.on("click", (event) => {
				if (!(event.nodes?.length === 1)) {
					setSelectedNode({});
				};
			});
			}, [nodes, edges, setSelectedNode, setVisJsNetwork]);

	return (
		<div
			ref={visJsRef}
			style={{
				height: `100%`,
				width: `100%`,
			}}
		/>
	);
};

export default RelatedNetwork;