import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { getImage } from '../utils/cockpit';
import VideoPlayer from './VideoPlayer';
import AudioPlayer from './AudioPlayer';

const MessageContentItemText = (props) => {
  const { item } = props;

  return (
    <ReactMarkdown>{item.value}</ReactMarkdown>
  );
}

const MessageContentItemImage = (props) => {
  const { item, windowDimensions } = props;
  const { windowWidth } = windowDimensions;
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (item.value?.path) {
      getImage(item.value.path, windowWidth * window.devicePixelRatio, windowWidth * window.devicePixelRatio, 64, setImgSrc, []);
    }
  }, [item.value, windowWidth]);

  if (imgSrc !== '') {
    return (
      <img className="message__content__img" src={imgSrc} alt={item.value.title} />
    );
  } else {
    return null;
  }
}

const MessageContentItemVideo = (props) => {
  const { item } = props;

  return (
    <VideoPlayer url={item.value} />
  );
}

const MessageContentItemAudio = (props) => {
  const { item } = props;

  return (
    <AudioPlayer url={item.value} />
  );
}

const MessageContentItemFile = (props) => {
  const { item } = props;

  return (
    <a download href={item.value}>Download</a>
  );
}

const MessageContentItem = (props) => {
  const { item } = props;

  switch (item.field.name) {
    case 'text':
      return <MessageContentItemText {...props} />;
      case 'image':
        return <MessageContentItemImage {...props} />;
      case 'video':
        return <MessageContentItemVideo {...props} />;
      case 'videoEmbed':
        return <MessageContentItemVideo {...props} />;
      case 'audio':
        return <MessageContentItemAudio {...props} />;
      case 'audioEmbed':
        return <MessageContentItemAudio {...props} />;
      case 'file':
        return <MessageContentItemFile {...props} />;
    default:
      return null;
  }
}

const Message = (props) => {

  const { node, windowDimensions, setSelectedNode, visJsNetwork } = props;

  return (
    <div className="message">
      <div className="message__inner">
        <header className="message__header">
          <h2 className="message__title">{node.name}</h2>
          <button className="message__close"
            onClick={(e) => {
              e.preventDefault();
              setSelectedNode({});
              if (visJsNetwork.body) {
                visJsNetwork.selectEdges([]);
                visJsNetwork.selectNodes([]);
              }
            }}
          >Close</button>
        </header>
        <div className="message__content">
          {
            node.content && node.content[0] &&
            node.content.map(
              (item, i) => (
                <MessageContentItem item={item} windowDimensions={windowDimensions} key={i} />
              )
            )
          }
        </div>
      </div>
    </div>
  );
}

export default Message;